export const jobData = {
  fullStackDev: {
    title: "Full Stack Developer",
    jobPurpose: "To manage web development operations and gain a deep understanding of our global web applications.",
    
    responsibilities: [
      "Design and implement efficient workflows for front-end and back-end development.",
      "Manage code versioning and deployment processes.",
      "Ensure alignment of project deliverables with development plans and timelines.",
      "Conduct effective resource allocation for development tasks.",
      "Adhere to coding standards and best practices for all development activities.",
      "Maintain accurate documentation of development processes and compliance requirements.",
      "Ensure adherence to web accessibility, security, and performance standards.",
      "Develop and implement strategies for website optimization and troubleshooting.",
      "Collaborate with stakeholders to address technical challenges and requirements.",
      "Manage database integration and data retrieval processes effectively.",
    ],

    jobDetails: [
      { icon: "loc", label: "Location", value: "Work from Office" },
      { icon: "jobtype", label: "Job Type", value: "Full Time" },
      { icon: "Exp", label: "Experience", value: "Experience: 1-3 year" },
      { icon: "Vacancy", label: "Vacancy", value: "No of Vacancy: 1" },
      { icon: "hour", label: "Working Hours", value: "10AM - 5:30 PM" },
      { icon: "working", label: "Working Days", value: "Weekly: 5days" },
    ],

    qualifications: [
      "Bachelor's degree in Computer Science, Information Technology, or a related field.",
      "Minimum of 2 years of experience in web development or related operations.",
      "Strong proficiency in debugging, troubleshooting, and problem-solving in web applications.",
      "Knowledge of web accessibility standards and security best practices.",
      "Familiarity with regulatory requirements for data privacy and compliance in web development.",
      "Commitment to delivering high-quality, user-focused web solutions.",
    ],

    competencies: [
      "Foster positive collaboration and communication within development teams.",
      "Exhibit ownership, commitment, respect, and a strong sense of teamwork in all projects.",
      "Demonstrate flexibility and adaptability in fast-paced and dynamic development environments.",
      "Possess strong verbal and written communication skills for effective stakeholder engagement.",
      "Showcase excellent analytical, debugging, and decision-making skills to solve complex technical challenges.",
    ],
  },

  uiDesigner: {
    title: "UI/UX Designer",
    jobPurpose: "To design intuitive and visually appealing user interfaces that provide seamless and engaging user experiences.",
    
    responsibilities: [
      "Conduct user research to understand target audience behaviors, needs, and preferences.",
      "Develop user personas, customer journey maps, and wireframes to guide design strategies.",
      "Create high-fidelity designs, mockups, and prototypes for web and mobile applications",
      "Ensure designs are responsive, accessible, and aligned with brand guidelines.",
      "Conduct usability testing and gather feedback to refine designs",
      "Continuously improve designs based on user insights and analytics.",
      "Stay updated with the latest design trends, tools, and technologies.",
      "Use tools like Figma, Adobe XD, Sketch, or similar for creating and presenting designs.",
    ],

    jobDetails: [
      { icon: "loc", label: "Location", value: "Hybrid" },
      { icon: "jobtype", label: "Job Type", value: "Full Time" },
      { icon: "Exp", label: "Experience", value: "Experience: 1-3 years" },
      { icon: "Vacancy", label: "Vacancy", value: "No of Vacancy: 1" },
      { icon: "hour", label: "Working Hours", value: "9AM - 6:00 PM" },
      { icon: "working", label: "Working Days", value: "Weekly: 5days" },
    ],

    qualifications: [
      "Bachelor’s degree in Graphic Design, Interaction Design, Computer Science, or a related field (or equivalent experience)",
      "Proficiency in design tools such as Figma, Sketch, Adobe XD, or Photoshop.",
      "Understanding of front-end technologies (HTML, CSS, JavaScript) is a plus",
      "Strong knowledge of UI/UX principles, design systems, and accessibility standards.",
      "2+ years of professional experience in UI/UX design or related fields.",
      "A strong portfolio showcasing user-centric designs and projects.",
    ],

    competencies: [
      "Ability to think innovatively and create visually appealing designs that solve user problems.",
      "Strong teamwork and communication skills to work effectively with cross-functional teams.",
      "A user-first mindset with the ability to understand and address user needs.",
      "Flexibility to adapt to evolving design trends and project requirements.",
      "Strong focus on precision and quality in design and execution.",
    ],
  },

 mobiledev: {
    title: "Mobile Application Developer",
    jobPurpose: "To design, develop, and maintain high-quality mobile applications for iOS and Android platforms.",
    
    responsibilities: [
      "Design and build mobile applications using native or cross-platform technologies (e.g., Swift, Kotlin, Flutter, React Native).",
      "Write clean, maintainable, and efficient code while adhering to industry standards.",
      "Optimize applications for maximum speed, performance, and scalability.",
      "Perform rigorous testing to identify and resolve application bugs",
      "Conduct performance tuning and ensure compatibility across various devices and OS versions.",
      "Integrate mobile applications with back-end services and APIs.",
      "Deploy apps to app stores and ensure adherence to platform guidelines.",
      "Provide recommendations for improving development practices and user experiences.",
   
    ],

    jobDetails: [
      { icon: "loc", label: "Location", value: "Remote" },
      { icon: "jobtype", label: "Job Type", value: "Full Time" },
      { icon: "Exp", label: "Experience", value: "Experience: 3-5 years" },
      { icon: "Vacancy", label: "Vacancy", value: "No of Vacancy: 1" },
      { icon: "hour", label: "Working Hours", value: "Flexible Hours" },
      { icon: "working", label: "Working Days", value: "Weekly: 5days" },
    ],

    qualifications: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field (or equivalent experience).",
      "Proficiency in mobile development languages like Swift (iOS), Kotlin (Android), or cross-platform tools like Flutter and React Native.",
      "Familiarity with RESTful APIs and mobile backend services.",
      "Experience with version control tools like Git",
      "Knowledge of deploying apps to Google Play Store and Apple App Store.",
      "2+ years of professional experience in mobile app development or related fields.",
    ],

    competencies: [
      "Ability to analyze complex issues and develop effective solutions promptly.",
      "Strong teamwork skills to work effectively with cross-functional teams and stakeholders.",
      "Flexibility to adapt to changing project requirements, tools, and technologies.",
      "A focus on quality and precision in coding, testing, and debugging.",
      "Ability to prioritize tasks and meet project deadlines effectively..",
    ],
  },
};