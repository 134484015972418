// DynamicTitle.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function DynamicTitle() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      "/": "Smart City & AI Solutions - Sival Devops",
      "/squarebric": "Squarebric - Sival Devops",
      "/careers": "Career Opportunities - Sival Devops ",
      "/contact-us": "Contact Us - Sival Devops",
      "/trafficmanagemnt": "Traffic Management - Sival Devops ",
      "/smokefire": "Smoke and Fire Detection - Sival Devops",
      "/facerecognition": "Face Recognition - Sival Devops",
      "/dwellanalysis": "Dwell Time Analysis - Sival Devops",
      "/intrusion": "Intrusion Detection - Sival Devops ",
      "/oursolution": "Our Solutions - Sival Devops",
      "/about-us": "About Us - Sival Devops",
      "/crowd": "Crowd Management - Sival Devops",
      "/unattended": "Unattend Object Detection - Sival Devops",
      "/gundetection": "Gun Detection - Sival Devops",
      "/job-description": "Job Description- Sival Devops"
    };

    // Update the title or use a default if the path is not defined
    document.title = pageTitles[location.pathname] || "Sival Devops";
  }, [location]);

  return null; // This component doesn't render anything visible
}

export default DynamicTitle;
