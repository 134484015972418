import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Scroll from "./Scroll";
import Untend from "./components/pages/untend/Untend";
import DynamicTitle from "./Dynamictitle";
import Jobs from "./components/pages/JobDescription/Jobs";

// Lazy load components
const HeroSection = React.lazy(() => import("./components/header/HeroSection"));
const Otherfield = React.lazy(() => import("./OurOtherFields/Otherfield"));
const Careers = React.lazy(() => import("./components/pages/careers/Careers"));
const ContactUs = React.lazy(() =>
  import("./components/pages/contact-us/Contact-Us")
);
const Trafficmanagemnt = React.lazy(() =>
  import("./components/pages/trafficmanagement/Trafficmanagemnt")
);
const Smokefire = React.lazy(() =>
  import("./components/pages/smokeand fire/Smokefire")
);
const Facerecognition = React.lazy(() =>
  import("./components/pages/facerecognition/Facerecognition")
);
const Dwellanalysis = React.lazy(() =>
  import("./components/pages/dwelltimeanalysis/Dwellanalysis")
);
const Oursolution = React.lazy(() =>
  import("./components/pages/Oursolutions/Oursolution")
);
const Crowd = React.lazy(() => import("./components/pages/crowd/Crowd"));
const Intrusion = React.lazy(() =>
  import("./components/pages/intrusion/Intrusion")
);
const Gundetection = React.lazy(() =>
  import("./components/pages/gundetection/Gundetection")
);
const AboutUs = React.lazy(() => import("./components/about-us/About-Us"));

function App() {
  return (
    <BrowserRouter>
      <DynamicTitle /> {/* Add DynamicTitle here */}
      <div className="app">
        <Scroll />
        <Header />
        <main>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-screen">
                <div className="loader border-t-4 border-b-4 border-blue-500 w-12 h-12 rounded-full animate-spin"></div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<HeroSection />} />
              <Route path="/squarebric" element={<Otherfield />} />
          
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/trafficmanagemnt" element={<Trafficmanagemnt />} />
              <Route path="/smokefire" element={<Smokefire />} />
              <Route path="/facerecognition" element={<Facerecognition />} />
              <Route path="/dwellanalysis" element={<Dwellanalysis />} />
              <Route path="/intrusion" element={<Intrusion />} />
              <Route path="/oursolution" element={<Oursolution />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/crowd" element={<Crowd />} />
              <Route path="/unattended" element={<Untend />} />
              <Route path="/gundetection" element={<Gundetection />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/job-description/:jobType" element={<Jobs />} />
              {/* Catch-all route for undefined pages */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
